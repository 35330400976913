import classNames from 'classnames';
import React from 'react'

import { IHomepageSliderComponent } from '@components/pages/home/slider/index';
import ErrorBoundary from '@components/common/ErrorBoundary';
import SwiperComponent from '@components/common/Swiper';
import { useScreenSize } from '@hooks/useScreenSize';

import HomepageStyles from './styles';



interface IHomepageSliderContainerProps {
  component: IHomepageSliderComponent;
  className?: string;
  slides: React.JSX.Element[];
}

const HomepageSliderContainer: React.FunctionComponent<IHomepageSliderContainerProps> = (props) => {
  const { isMobile } = useScreenSize();
  const classes = HomepageStyles();

  const { component, slides } = props;

  return (
    <ErrorBoundary>
      <div className={classNames(classes.homepageSliderSwiper, 'home-slider', props.className)}>
        <SwiperComponent autoplay={component?.automaticimageswitching || false}
                         slides={slides}
                         slidesPerView={1}
                         spaceBetween={0}
                         pagination={true}
                         navigation={!isMobile}
                         loop={true}
                         delay={(component?.interval || 3) * 1000}
        />
      </div>
    </ErrorBoundary>
  );
};

export default HomepageSliderContainer;
