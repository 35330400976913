import { createStyles, makeStyles } from '@mui/styles';

import { ColorsPalette } from '@themes/colors';
import { zIndex } from '@themes/zIndex';

export default makeStyles((theme) =>
  createStyles({
    button: {
      '&:hover': {
        background: ColorsPalette.hoverActionCTA,
      },
      alignItems: 'center',
      background: ColorsPalette.primary,
      border: '0',
      borderRadius: '50%',
      cursor: 'pointer',
      display: 'flex',
      height: 40,
      justifyContent: 'center',
      lineHeight: '10px',
      opacity: '1',
      outline: '0',
      position: 'absolute',
      top: 'calc(50% - 20px)',
      transition: 'all .5s',
      width: 40,
      zIndex: zIndex.sliderButtons,
    },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      top: '50%',
    },
    buttonGroupProducts: {
      '& $button': {
        top: 'calc(50% - 20px - 90px)',
      },
    },
    hideElement: {
      display: 'none',
    },
    left: {
      left: '-20px',
      mozTransform: 'rotate(180deg)',
      msTransform: 'rotate(180deg)',
      oTransform: 'rotate(180deg)',
      transform: 'rotate(180deg)',
      webkitTransform: 'rotate(180deg)',
    },
    right: {
      right: '-20px',
    },
    swiper: {
      '& .swiper-wrapper': {
        display: 'flex',
        //   overflowY: 'hidden',
      },
      position: 'relative',
    },
    swiperPreload: {
      '& .swiper-wrapper .swiper-slide-duplicate': {
        display: 'none',
      },
    },
  }),
);
