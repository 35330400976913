import React from 'react';

import Link from '@components/common/Link';
import { getMediaUrl } from '@constants/urls';
import getStyles from './styles';

export interface IHomepageSliderImageProps {
  alt: string;
  aspectRatio: string | null;
  link?: string;
  path?: string;
  title?: string;
  button1Link?: string;
  button1Text?: string;
  button2Link?: string;
  button2Text?: string;
  buttonsPositionLeft?: number;
  buttonsPositionTop?: number;
  buttonsWidth?: number;
}

export const HomepageSliderImage: React.FunctionComponent<IHomepageSliderImageProps> = ({
  aspectRatio,
  link,
  path,
  title,
  alt,
  button1Link,
  button1Text,
  button2Link,
  button2Text,
  buttonsPositionLeft,
  buttonsPositionTop,
  buttonsWidth,
}) => {
  const classes = getStyles({});

  return (
    <div className={classes.slide}>
      <div className={classes.slideContainer}>
        <Link legacyBehavior href={link || '#'}>
          <a className={classes.image}>
            <img style={aspectRatio ? { aspectRatio } : {}} src={getMediaUrl(path || '')} alt={alt} />
          </a>
        </Link>
        {title && (
          <div className={classes.slideButtons}>
            <div className={classes.slideButtonsTitle}>
              <span className={classes.slideButtonsTitleIcon} />
              <span className={classes.slideButtonsTitleText}>{title}</span>
            </div>
          </div>)}
        {((button1Link && button1Text) || (button2Link && button2Text)) && (
          <div className={classes.buttons} style={{
            left: buttonsPositionLeft ? buttonsPositionLeft : '75%',
            position: 'absolute',
            top: buttonsPositionTop ? buttonsPositionTop : '89%',
            width: buttonsWidth || 'auto',
          }}>
            {button1Link && button1Text && (
              <div className={classes.slideButtonsButton}>
                <Link legacyBehavior href={button1Link}>
                  <a className={classes.slideButtonsButtonText} title={button1Text}>
                    {button1Text}
                  </a>
                </Link>
              </div>
            )}
            {button2Link && button2Text && (
              <div className={classes.slideButtonsButton}>
                <Link legacyBehavior href={button2Link}>
                  <a className={classes.slideButtonsButtonText} title={button2Text}>
                    {button2Text}
                  </a>
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
