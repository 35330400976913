import classNames from 'classnames';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import SwiperCore, { Autoplay, Pagination, Virtual, Zoom } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Hidden from '@components/common/Hidden';
import ArrowForwardIcon from '@icons/ArrowForward';
import styles from './styles';


import 'swiper/css/zoom';

interface ISwiperComponentProps {
  afterChange?: (slideIndex: number) => void;
  autoplay?: boolean;
  className?: string;
  cssMode?: boolean;
  delay?: number;
  initialSlide?: number;
  loop?: boolean;
  mobile?: boolean;
  navigation?: boolean;
  productList?: boolean;
  spaceBetween?: number;
  slides: JSX.Element[];
  slidesPerView?: number;
  pagination?: boolean;
  zoom?: boolean;
  virtual?: boolean;
}

export interface SwiperComponentInstanceRef {
  goToSlide: (slideIndex: number) => void;
}

const SwiperComponent = forwardRef<SwiperComponentInstanceRef, ISwiperComponentProps>(({
  afterChange,
  autoplay = false,
  className,
  cssMode = true,
  delay = 3000,
  initialSlide = 0,
  loop = false,
  mobile = false,
  navigation = true,
  productList = true,
  spaceBetween = 15,
  slides,
  slidesPerView = 5,
  pagination = true,
  zoom = false,
  virtual = false,
}, ref) => {
  const classes = styles();

  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
  const [onStart, setOnStart] = useState<boolean>(true);
  const [onEnd, setOnEnd] = useState<boolean>(false);

  const [slidesPerViewMobile, setSlidesPerView] = useState<number>(1.33);

  const resizeHandler = () => {
    const screenWidth: number = window?.innerWidth || 360;
    const newSlidesPerView = screenWidth / (244 + 15);
    setSlidesPerView(newSlidesPerView);
  };

  useImperativeHandle(ref, () => ({
    goToSlide: (slideIndex) => {
      swiperInstance?.slideTo(slideIndex);
    },
  }), [swiperInstance]);

  useEffect(() => {
    window?.addEventListener('resize', resizeHandler);
    return () => window?.removeEventListener('resize', resizeHandler);
  }, []);

  const onProgressChanged = (progress: number) => {
    if (progress === 1 && !onEnd) {
      setOnEnd(true);
    }
    if (progress !== 1 && onEnd) {
      setOnEnd(false);
    }
    if (progress === -0 && !onStart) {
      setOnStart(true);
    }
    if (progress !== -0 && onStart) {
      setOnStart(false);
    }
  };

  // const slidesLength = swiperInstance?.slides.length || 0;
  const slidesLength = slides.length || 0;

  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.update();
    }
  }, [swiperInstance]);

  return (
    <div className={classNames(classes.swiper, className, { [classes.swiperPreload]: !swiperInstance })}>
      <Swiper
        cssMode={false}
        autoplay={autoplay ? { delay } : false}
        followFinger={false}
        loop={loop}
        modules={[Pagination, Autoplay, Virtual, Zoom]}
        onSwiper={(instance: SwiperCore) => setSwiperInstance(instance)}
        onProgress={(instance: SwiperCore, progress: number) => {
          onProgressChanged(progress);
        }}
        onSlideChange={(swiperS) => {
          if (afterChange) {
            afterChange(swiperS.activeIndex);
          }
        }}
        pagination={pagination ? { clickable: true } : false}
        spaceBetween={spaceBetween}
        slidesPerView={mobile ? (slidesPerViewMobile > 1.48 ? 1.48 : slidesPerViewMobile) : slidesPerView}
        slidesPerGroup={1}
        longSwipes={false}
        shortSwipes={true}
        initialSlide={initialSlide}
        zoom={zoom}
        virtual={virtual}
      >
        {slides.map((slide, key) => (
          <SwiperSlide key={`slide${key}`}>{slide}</SwiperSlide>
        ))}
      </Swiper>
      <Hidden mdDown={true}>
        {navigation && slidesLength > slidesPerView && (
          <div
            className={classNames(classes.buttonGroup, { [classes.buttonGroupProducts]: productList })}>
            <div
              className={classNames('swiperNavigationButton', classes.button, classes.left, { [classes.hideElement]: onStart && !loop })}
              onClick={(e) => swiperInstance?.slidePrev()}
            >
              <ArrowForwardIcon />
            </div>
            <div
              className={classNames('swiperNavigationButton', classes.button, classes.right, { [classes.hideElement]: onEnd && !loop })}
              onClick={(e) => swiperInstance?.slideNext()}
            >
              <ArrowForwardIcon />
            </div>
          </div>
        )}
      </Hidden>
    </div>
  );
});

SwiperComponent.displayName = 'SwiperComponent';
export default SwiperComponent;
