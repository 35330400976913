import classNames from 'classnames';
import React, { useMemo } from 'react';

import HomepageSliderContainer from '@components/pages/home/slider/container';
import { mobileMode } from '@constants/config';
import { IContentShow } from '@interfaces/pageData/reducer';
import ErrorBoundary from '@components/common/ErrorBoundary';
import { HomepageSliderImage } from '@components/pages/home/slider/Slide';
import { IHomePageComponentDataObject } from '@interfaces/home';

import HomepageStyles from './styles';

import Hidden from '@components/common/Hidden';

interface IHbsMedia {
  media: string;
  link: string;
  height?: number;
  width?: number;
  content_show?: IContentShow;
}

export interface IHomepageSliderComponent {
  bannerLink: string;
  button1Link: string;
  button1Text: string;
  button2Link: string;
  button2Text: string;
  buttonsPositionLeft: number;
  buttonsPositionTop: number;
  buttonsWidth: number;
  hbsmedia: IHbsMedia[];
  show: boolean;
  showInApp: boolean;
  showInMobile: boolean;
  title: string;
  type: string;
  automaticimageswitching?: boolean;
  interval?: number;
}

interface IHomepageSliderProps {
  component: IHomePageComponentDataObject;
  className?: string;
}

const HomepageSlider: React.FunctionComponent<IHomepageSliderProps> = (props) => {
  const classes = HomepageStyles();
  const defaultComponent: IHomepageSliderComponent = {
    bannerLink: '',
    button1Link: '',
    button1Text: '',
    button2Link: '',
    button2Text: '',
    buttonsPositionLeft: 0,
    buttonsPositionTop: 0,
    buttonsWidth: 0,
    hbsmedia: [],
    show: true,
    showInApp: true,
    showInMobile: true,
    title: '',
    type: '',
  };
  const component: IHomepageSliderComponent = { ...defaultComponent, ...props.component };

  const filterDesktop = (item: IHbsMedia) => {
    return mobileMode ? item.content_show?.show_in_app : item.content_show?.show;
  };
  const filterMobile = (item: IHbsMedia) => {
    return mobileMode ? item.content_show?.show_in_app : item.content_show?.show_in_mobile;
  };

  const mapSlides = (slideItem: IHbsMedia, index) => {
    const { media, link, height, width } = slideItem;
    return <HomepageSliderImage key={`HomepageSliderImage${index}`}
                                path={media}
                                alt={component?.title || 'Home slider'}
                                title={component?.title}
                                link={link}
                                button1Text={component?.button1Text}
                                button1Link={component?.button1Link}
                                button2Text={component?.button2Text}
                                button2Link={component?.button2Link}
                                buttonsWidth={component?.buttonsWidth}
                                buttonsPositionTop={component?.buttonsPositionTop}
                                buttonsPositionLeft={component?.buttonsPositionLeft}
                                aspectRatio={width && height ? `${width}/${height}` : null}
    />;
  };

  const hbsMedia: IHbsMedia[] = Array.isArray(component.hbsmedia) ? component.hbsmedia : [];

  const hbsMediaDesktop: IHbsMedia[] = useMemo(()=>hbsMedia.filter(filterDesktop), [JSON.stringify(hbsMedia)]);
  const hbsMediaMobile: IHbsMedia[] = useMemo(()=>hbsMedia.filter(filterMobile), [JSON.stringify(hbsMedia)]);

  const itemsListDesktop = useMemo(()=> hbsMediaDesktop.map(mapSlides), [hbsMediaDesktop]);
  const itemsListMobile = useMemo(()=> hbsMediaMobile.map(mapSlides), [hbsMediaMobile]);
  return (
    <ErrorBoundary>
      {itemsListDesktop.length > 0 && (
      <Hidden key={`HomepageSliderDesktop`}
              mdDown={true}
              mdUp={false}>
        <div className={classNames(classes.homepageSliderSwiper, 'home-slider', props.className)}>
          <HomepageSliderContainer
            slides={itemsListDesktop}
            component={component}
            className={props.className}
          />
        </div>
      </Hidden>
      )}
      {itemsListMobile.length > 0 && (
      <Hidden key={`HomepageSliderMobile`}
              mdDown={false}
              mdUp={true}>
        <HomepageSliderContainer
          slides={itemsListMobile}
          component={component}
          className={props.className}
        />
      </Hidden>
        )}
    </ErrorBoundary>
  );
};



export default HomepageSlider;
