import React from 'react';

const ArrowForwardIcon: React.FunctionComponent<{ className?: string; }> = ({ className }) => {
  return (
    <svg className={className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.9987 0.333008L5.8237 1.50801L10.4737 6.16634L0.332031 6.16634L0.332031 7.83301L10.4737 7.83301L5.8237 12.4913L6.9987 13.6663L13.6654 6.99967L6.9987 0.333008Z"
        fill="white" />
    </svg>
  );
};

export default ArrowForwardIcon;
